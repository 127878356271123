<template>
  <ClientOnly>
    <div class="p-md">
      <FormKit
        :id="id"
        type="form"
        class="max-w-md"
        :actions="false"
        :incomplete-message="false"
        spellcheck="false"
        :config="{ validationVisibility: 'blur' }"
        @submit="submit"
      >
        <div ref="el">
          <div class="js-error-container">
            <slot name="errors" />
          </div>
          <div v-if="info" class="mt-sm">
            <InfoFrame>
              {{ t(info) }}
            </InfoFrame>
          </div>
        </div>

        <slot />

        <ButtonBasic
          classes="w-full px-sm md:px-0 pt-sm"
          :label="t(submitLabel)"
          :type="EButtonType.SUBMIT"
          :btn-style="btnStyle"
          :loading-animation="isLoading"
          :disabled="isLoading"
          full-width
          @click="checkValidity"
        ></ButtonBasic>
        <slot name="buttons" />
        <div
          class="flex items-center justify-center w-full gap-2 cursor-pointer text-primary-base mt-md"
          @click="$emit('cancel')"
        >
          <span class="anim__underline-link anim-link">
            {{ t(cancelLabel) }}
          </span>
        </div>
      </FormKit>
    </div>
  </ClientOnly>
</template>
<script setup lang="ts">
import { EButtonStyle, EButtonType } from '~~/src/@types/basic-button';
import ButtonBasic from '~~/src/components/components/core/basic-button/basic-button.vue';
import InfoFrame from '~/components/formFields/components/infoFrame.vue';
import { FormKit } from '@formkit/vue';

const { t } = useTrans();
const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  headline: {
    type: String,
    default: '',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  submitLabel: {
    type: String,
    default: '',
  },
  info: {
    type: String,
    default: '',
  },
  cancelLabel: {
    type: String,
    default: 'formFields.form.cancel',
  },
  btnStyle: {
    type: String,
    default: EButtonStyle.DEFAULT,
  },
});

const emit = defineEmits<{
  (e: 'submit', data: any): void;
  (e: 'cancel'): void;
}>();
function submit(data: any) {
  //needed because when the form is subitted while the input is focused, formkit throws an error when it tries to blur the already unmounted input
  (document.activeElement as HTMLElement)?.blur();
  emit('submit', data);
}
const el = ref<HTMLElement | null>(null);

async function checkValidity() {
  const form = document.getElementById(props.id);
  if (!form) return;

  // Sleep for quarter second till form is updated with validation information
  await new Promise((r) => setTimeout(r, 250));
  const invalidElements = form.querySelectorAll('[data-invalid=true]');

  if (!invalidElements.length) return;

  invalidElements[0].scrollIntoView({
    behavior: 'smooth',
  });
}
</script>
